.soc-share {

	font-size: 30px;

	text-align: center;

}

.soc-share a:hover {

	opacity: 0.6;

}

.work-intro .social-share-w {

	position: absolute;

	bottom: 100%;

	left: 0;

	right: 0;

	width: 100%;

}

#connect .work-intro {

	overflow: visible;

	height: auto;

}

.slider-has-ss {

	position: relative;

	overflow: hidden;

}

.slider-has-ss .social-share-w {

	position: absolute;

	top: -20%;

	left: 0;

	right: 0;

	width: 100%;

	z-index: 999;

	background: rgba( 255, 255, 255, 0.5 );

	transition: top 1s;

}

.slider-has-ss:hover .social-share-w {

	top: 10%;

}

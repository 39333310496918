.justified {
    text-align: justify;
}

.disable-text-transform {
    text-transform: none !important;
}

.white-text {
    color: #fff;
}

.green-text {
    color: #ff00ae;
}

.bold-text {
    font-weight: bold;
}

.main-nav.dark .inner-nav ul > li > a {
    font-weight: bold;
}

.main-nav.dark .inner-nav ul > li > a:hover, .main-nav.dark .inner-nav ul > li > a.active {
    color: #ff00ae;
}

.purple-btn {
	background: #ff00ae none repeat scroll 0 0;
}

.fluid-img {
    width: 100%;
    height: auto;
}

table.dj-equipment tbody tr td, table.dj-equipment tfoot tr td, table.dj-equipment thead tr th {
    text-align: center;
}

.sc-profile a {
    text-decoration: none;
}

.mn-sub.sub-left {
    left: -100%;
}

.small-desc {
    margin-bottom: 5px;
    font-size: 10px;
}

.valign-middle {
    vertical-align: middle !important;
}

.choose-for-purchase, .choose-for-purchase-addon {
    cursor: pointer;
}

.table-radio {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    text-align: center;
}

.table-radio input {
    position: static !important;
    margin: 30% 0 0 0 !important;
}

.hidden {
    display: none;
}

.owl-item > div {
    padding-bottom: 20px;
}

.owl-pagination {
    bottom: -5px;
}

.mod-breadcrumbs a, .mod-breadcrumbs a:visited {
    color: #ff00ae;
    font-weight: 700;
}

.mod-breadcrumbs a:hover, .mod-breadcrumbs a:active {
    color: #fff;
}

.bootstrap-datetimepicker-widget {
    z-index: 999;
    border: 1px solid #ccc;
    position: absolute;
    background-color: #fff;
}

.showOver {

    overflow: visible;

}

.form-group.has-error input, .form-group.has-error textarea, .form-group.has-error select, .form-group.has-danger input, .form-group.has-danger textarea, .form-group.has-danger select {

    border-color: #e41919;

}

.form td.form-group {
    margin: auto !important;
}

.font-s {
    font-size: 10px;
}


.plr {
    padding-left: 10px;
    padding-right: 10px;
}

.anim-box {
	width: 100%;
    height: 358px;
    display: block;
    background: url(../images/hbg.jpg) no-repeat center #000;
    background-size: contain;
    color: #fff;
}

.anim-box-inner {
	width: 100%;
    height: 350px;
    display: block;
    color: #fff;
    position: relative;
}

.anim-box-inner .cont {
    display: block;
    text-align: center;
    width: 100%;
    padding: 10px 0;
    /*background: url(../images/opa-bg.png) repeat center;*/
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate( 0, -50% );
}


.hvr-outline-out {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    border: 4px solid #333;
}

.hvr-outline-out:before {
  content: '';
  position: absolute;
  border: #fff solid 4px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: top, right, bottom, left;
  transition-property: top, right, bottom, left;
}

.hvr-outline-out:hover, .hvr-outline-out:focus, .hvr-outline-out:active {
    border-color: #333;
}

.hvr-outline-out:hover:before, .hvr-outline-out:focus:before, .hvr-outline-out:active:before {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
}

.hvr-fade {
  background-color: #000;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  overflow: hidden;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-property: color, background-color, font-size;
  transition-property: color, background-color, font-size;
}

.hvr-fade .cont {

    -webkit-transition: transform 0.6s ease-out;
    transition: transform 0.6s ease-out;
}

.hvr-fade:hover, .hvr-fade:focus, .hvr-fade:active {
  background-color: transparent;
  /*color: #333;*/
}

.hvr-fade:hover .cont, .hvr-fade:focus .cont, .hvr-fade:active .cont {

    transform: scale(0.5)  translate( 0, -100% );

}

.services-animation-ww {
    background-color: #000;
}

.services-animation {

    display: block;
    text-align: center;
    /*border: 5px solid #333;
    transition: border-color 0.5s;*/

}

/*.services-animation:hover, .services-animation:active {
    border-color: #ccc;
}*/

.services-animation video {

    display: block;
    width: 100%;
    height: auto;

}


.footer-social-links-fixed {
    position: fixed;
    display: block;
    text-align: center;
    width: 50px;
    left: 0;
    top: 50%;
    transform: translate( 0, -50% );
    z-index: 99;
    transition: all 0.5s;
}

@media only screen and (max-width: 720px) {

  	.footer-social-links-fixed {

        transform: none;
        top: auto;
        bottom: 10%;

        display: none !important;

    }

}

.footer-social-links-fixed a {
    float: left;
    display:block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align:center;
    font-size: 20px;
    color:#fff;
}

.footer-social-links-fixed a img {
    max-width: 100%;
}

.map-toggle {
    width:300px;
    margin:-50px 0 0 -150px;
}

.sub-from-bottom {
    top: auto !important;
    bottom: 0 !important;
}

#content .small-section {
    padding-top: 75px !important;
}

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::--webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works */

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}


#review-container {

    display: inline-block;

    width: 100%;

}

#review-container .romw {

    padding-bottom: 10px;

    /*border-bottom: 1px solid #888;*/

    margin-bottom: 20px;

}

#review-container > p {

    display: none !important;

}

#home .home-text {

    vertical-align: bottom;

}

#call2action, #call2actionmob {

    margin-bottom: 60px;

}

.work-descr {

    margin-bottom: 8px;

}

.full-page, .spec-table {
    overflow: visible;
}

/*html {

    overflow-x: auto;

}*/


.no-mobile #call2action {

    display: inline-block;

}

.no-mobile #call2actionmob {

    display: none;

}

.mobile #call2action {

    display: none;

}

.mobile #call2actionmob {

    display: inline-block;

}

.dj-desc {

    margin-top: -30px;

}

.live-mus .dj-desc {

    margin-top: -10px;

}

.dj-desc p {

    font-size: 10px;

    margin-bottom: 0;

    letter-spacing: normal;

}

.dj-desc a {

    margin-top: 8px;

}

.footer-social-links a img {

    position: relative;

    top: -3px;

    left: 0;

}

.footer-social-links a img.img-hover {

    display: none;

}

.footer-social-links a:hover img.img-hover {

    display: inline-block;

}

.footer-social-links a:hover img.img-normal {

    display: none;

}

.strike {

    text-decoration: line-through;

}

.red-price {

    color: red;

}

.red-strike {

    text-decoration: line-through;

    color: red;

}

.redd-strike {

    text-decoration: line-through;

}

.heading-notice {

    display: block;

    width: 100%;

    height: 100%;

    position: absolute;

    top: 0;

    left: 0;

    text-align: center;

    z-index: 12;

    line-height: 35px;

}

.nav-logo-wrap, .mobile-nav, .desktop-nav {

    position: relative;

    z-index: 13;

}

.center-no-bullet {

    list-style: none;
    list-style-type: none;

}

.center-no-bullet li {

    list-style: none;
    list-style-type: none;

}

.form-conf .conf-form-row {

    margin-bottom: 30px;

}

.form-conf .conf-form-act {

    margin-left: 30px;

}

.form-field-preview {

    display: none;

    margin: 0 25% 20px 25%;

    width: 50%;

    height: auto;

}

.cart-cell-row {

    display: block;

    margin: 5px;

}

.cart-cell-row-inside {

    display: block;

    margin: 5px;

    margin-left: 15px;

}

.alt-features-descr p {

    font-size: 18px;

    text-align: left;

}

.dtpick {

    position: relative;

}